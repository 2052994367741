import React from 'react';
import styles from './TitleCard.module.css';

import trovadori_logo from "../../img/Trovadori 1 scuro marrone.png"

const TitleCard = () => {
  return (
  	<div className={styles.TitleCard}>
		<p className={styles.title}>Trovadori</p>
		<div className={styles.logo_aligner}>
			<img src={trovadori_logo}  className={styles.logo} alt="main_logo_trovadori"></img>			
		</div>
		<p className={styles.subtitle}>Quindi hai deciso di fare un patto con una strega.</p>
		<p className={styles.subtitle}>Saggio da parte tua.</p>
  </div>)
}

export default TitleCard;
